import React from 'react';
import styled from 'styled-components';
import { Container, MarkdownStyles } from '../primitives';

const Section = styled.section.attrs({ className: 'relative bg-white' })``;

const Body = styled.div`
  max-width: 740px;
  margin: 0 auto;
  padding: 2rem 1rem 4rem;
`;

const Behavior = () => {
  //
  return (
    <Section>
      <Container>
        <Body>
          <MarkdownStyles>
            <h2>Behavior during Training Session</h2>
            <ol>
              <li>Maintain a cooperative and respectful attitude toward the coach.</li>
              <li>Maintain conversational voice levels.</li>
              <li>Be escorted to the pool deck by a parent and ready to begin practice on time.</li>
              <li>
                Swimmers should be in the pool area with all necessary equipment, ready to begin training at least{' '}
                <strong>10</strong> minutes before commencement of training session. This will allow for proper
                stretching. Coaches will also use this time to give pre-training instructions.
              </li>
              <li>
                <strong>NO</strong> running on the pool deck.
              </li>
              <li>
                <strong>NO</strong> diving without a coach’s permission at any time.
              </li>
              <li>
                <strong>NO</strong> pushing or shoving another person either in or out of the water.
              </li>
              <li>
                <strong>NO</strong> splashing.
              </li>
              <li>
                <strong>NO</strong> chewing of gum.
              </li>
              <li>
                <strong>NO</strong> spitting.
              </li>
              <li>
                <strong>NO</strong> obscene, derogatory, or profane behavior or language.
              </li>
              <li>Respect the personal property of teammates or restitution for damage will be expected.</li>
              <li>Be responsible for own swim gear and keep all facilities clean.</li>
              <li>
                Refrain from playfulness as deemed by the coach that can become frightening, harassing, bullying or
                hurtful to others.
              </li>
              <li>
                Demonstrate cooperativeness and respect for all persons involved with <strong>SDAA</strong>
              </li>
              <li>Gladly pitch-in if asked to assist a coach or parent member.</li>
              <li>
                Resolve differences through conversation and compromise. Disputes not successfully resolved verbally
                should be referred to the disciplinary committee member on deck.
              </li>
              <li>
                Practice lane etiquette. Swimmers who stop in the middle of any set for any reason should rejoin their
                lane as quickly as possible with the least disruption as possible.
              </li>
              <li>
                There should not be physical contact between swimmers, which would be considered unreasonable. The SDAA
                coaching staff and /or disciplinary committee will deal with these infractions immediately.
              </li>
              <li>Do not throw objects. Projectiles in the pool area can be dangerous.</li>
              <li>
                Refrain from the use of addictive substances. Any swimmer found to be using alcohol, illegal drugs,
                steroids or tobacco is subject to suspension from the team.
              </li>
              <li>
                Swim warm-up/warm-down as part of the training. The SDAA coaching staff will announce when
                warm-up/warm-down begins.
              </li>
              <li>
                At all <strong>SDAA</strong> functions, whether it be training, meets or social gatherings, the{' '}
                <strong>SDAA</strong> coaching staff expects all swimmers to behave in such a way that their actions
                reflect positively on the team.
              </li>
            </ol>
          </MarkdownStyles>
        </Body>
      </Container>
    </Section>
  );
};

export default Behavior;

Behavior.propTypes = {
  //
};

Behavior.defaultProps = {
  //
};
