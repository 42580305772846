import React from 'react';
import styled from 'styled-components';
import { Container, MarkdownStyles } from '../primitives';

const Section = styled.section.attrs({ className: 'relative bg-white' })``;

const Body = styled.div`
  max-width: 740px;
  margin: 0 auto;
  padding: 2rem 1rem 0;
`;

const Conduct = () => {
  //
  return (
    <Section>
      <Container>
        <Body>
          <MarkdownStyles>
            <h2>Attendance Policy</h2>
            <ol>
              <li>
                Each training group has specific attendance requirements appropriate for the objectives of that group.
                As a general rule, the least possible interruption in the training schedule will produce the greatest
                amount of success. The club does, however, encourage younger swimmers to participate in other activities
                in addition to swimming.
              </li>
              <li>Please be aware that training session schedules often change around swim meets.</li>
            </ol>
          </MarkdownStyles>
        </Body>
      </Container>
    </Section>
  );
};

export default Conduct;
