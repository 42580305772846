import React from 'react';
import styled from 'styled-components';
import { Container, MarkdownStyles } from '../primitives';

const Section = styled.section.attrs({ className: 'relative bg-gray-200' })``;

const Body = styled.div`
  max-width: 740px;
  margin: 0 auto;
  padding: 2rem 1rem 2rem;
`;

const Responsibity = () => {
  //
  return (
    <Section>
      <Container>
        <Body>
          <MarkdownStyles>
            <h2>Swimmers' Responsibility</h2>
            <ol>
              <li>
                As a swimmer’s level of swimming ability increases so does his/her responsibility. The program is
                designed to encourage all swimmers to be Senior Swimming bound.
              </li>
              <li>
                A swimmer has responsibilities to the team, the coach, his/her parents, and most importantly to
                themselves.
              </li>
              <li>Swimmers need to prepare themselves to give 100% effort each time they come for training.</li>
              <li>
                Swimmers are expected to bring specified training equipment (i.e., goggles, flippers, etc.) to training.
              </li>
              <li>
                Swimmers are to ensure their equipment is adjusted before the training session and that spares are
                readily available.
              </li>
              <li>Equipment adjustment and repairs are not acceptable reasons to miss part of a training session.</li>
              <li>
                Staying the entire traning session is encouraged. The last part of the training session is very often
                the most important. Usually, there are important announcements made at the end of each session.
              </li>
              <li>
                If a swimmer must leave the training session early, please notify the coach before the beginning of the
                session to minimize disruptions.
              </li>
            </ol>
          </MarkdownStyles>
        </Body>
      </Container>
    </Section>
  );
};

export default Responsibity;

Responsibity.propTypes = {
  //
};

Responsibity.defaultProps = {
  //
};
