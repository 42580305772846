import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { SEO } from '../components/primitives';
import Attendance from '../components/_conduct/Attendance';
import Responsibility from '../components/_conduct/Responsibity';
import Behavior from '../components/_conduct/Behavior';

const pageTransition = {
  initial: {},
  enter: {},
  exit: {},
};

const conduct = ({ location: { pathname } }) => {
  //

  return (
    <motion.article
      className="relative"
      key={pathname}
      initial="initial"
      animate="enter"
      exit="exit"
      variants={pageTransition}
    >
      <SEO title="Swimmer's Code of Conduct" description="Swimmer's Code of Conduct" />
      <Attendance />
      <Responsibility />
      <Behavior />
    </motion.article>
  );
};

export default conduct;

conduct.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

conduct.defaultProps = {
  //
};
